<template>
  <div>
    <div class="mb-3 text-grey">
      <b>BID BOND SUMMARY</b>
      <span class="ml-2">
        <i class="fa fa-info-circle" title="Bidbond Summary"></i>
    </span>
    </div>
    <div class="d-flex mb-1">
      <h2 class="mb-0">KES {{ total_sales.toLocaleString() }} </h2>
      <div class="ml-auto mt-n1 mb-n1">
        <apexchart :options="totalSalesChart.options" :series="totalSalesChart.series"
                   height="36px"></apexchart>
      </div>
    </div>
    <hr class="bg-white-transparent-2" />
    <div class="row text-truncate">
      <div class="col-6">
        <div class="f-s-12 text-grey">Units Sold</div>
        <div class="f-s-16 m-b-5 f-w-600 p-b-1">{{ units_sold.toLocaleString() }}</div>
        <div class="progress progress-xs rounded-lg bg-dark-darker m-b-5">
          <div class="progress-bar progress-bar-striped rounded-right bg-mediterranean" style="width: 55%"></div>
        </div>
      </div>
      <div class="col-6">
        <div class="f-s-12">Bid bond exposure</div>
        <div class="f-s-16 m-b-5 f-w-600 p-b-1">KES {{ bibond_price.toLocaleString() }}</div>
        <div class="progress progress-xs rounded-lg bg-dark-darker m-b-5">
          <div class="progress-bar progress-bar-striped rounded-right" style="width: 55%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "tabOne",
  props: ['startDate', 'endDate'],
  data() {
    return {
      total_sales: 0,
      units_sold: 0,
      bibond_price: 0,
      totalSalesChart: {
        options: {
          chart: {
            type: 'line',
            width: 200,
            height: 36,
            sparkline: {
              enabled: true
            },
            stacked: true
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: [{
                offset: 0,
                color: '#348fe2',
                opacity: 1
              },
                {
                  offset: 100,
                  color: '#8753de',
                  opacity: 1
                }]
            },
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return '';
                }
              },
              formatter: (value) => {
                return 'KES' + this.convertNumberWithCommas(value)
              },
            },
            marker: {
              show: false
            }
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: 130
              }
            }
          }, {
            breakpoint: 1300,
            options: {
              chart: {
                width: 100
              }
            }
          }, {
            breakpoint: 1200,
            options: {
              chart: {
                width: 200
              }
            }
          }, {
            breakpoint: 576,
            options: {
              chart: {
                width: 180
              }
            }
          }, {
            breakpoint: 400,
            options: {
              chart: {
                width: 120
              }
            }
          }]
        },
        series: [{
          data: [9452.37, 11018.87, 7296.37, 6274.29, 7924.05, 6581.34, 12918.14]
        }]
      },
    };
  },
  watch: {
    bids() {
      let counter = 0;
      let total_sales = 0;
      let bid_price = 0;
      this.bids.forEach((b) => {
        counter++;
        total_sales += parseInt(b.charge);
        bid_price += parseInt(b.amount);
      })
      this.units_sold = counter;
      this.total_sales = total_sales;
      this.bibond_price = bid_price;
    },
    period() {
      if (this.startDate && this.endDate) {
        this.fetchBids();
      }
    },
  },
  computed: {
    ...mapGetters({
      bids: 'getTabOneBids'
    }),
    period() {
      return [this.startDate, this.endDate].join();
    },
  },
  mounted() {
    if (!this.bids.length) {
      this.fetchBids()
    }
  },
  methods: {
    fetchBids() {
      let start_date = moment(this.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('fetchBidsTabOne', {start: start_date, end: end_date});
    },
    convertNumberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}
</script>

