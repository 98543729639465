<template>
<div>
  <div>
    <div class="card-body">
    <!-- begin title -->
    <div class="mb-3 ">
      <b class="mb-3">COMMISSION SUMMARY</b>
      <span class="ml-2"><i class="fa fa-info-circle" title="Commission Summary"></i></span>
    </div>
    <!-- end title -->
    <!-- begin conversion-rate -->
    <div class="d-flex align-items-center mb-1">
      <div class="ml-auto">
        <apexchart :options="conversionRateChart.options" :series="conversionRateChart.series"
                   height="28px"></apexchart>
      </div>
    </div>
    <!-- end conversion-rate -->
    <div class="mb-3">
      <i class="fa fa-caret-down"></i> Top 3 companies per commission
    </div>
    <div v-for="(com,key) in companies" :key="key" class="d-flex 100 mb-1">
      <div class="d-flex text-capitalize w-75">
        <i class="fa fa-circle text-red f-s-8 mr-1 mt-1"></i>
        <span class="text-capitalize">{{ com.company.name | truncate(20, '...') }}</span>
      </div>
      <div class=" f-w-600">{{ $number.format(com.charge) }}</div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "tabTwo",
  props: ['startDate', 'endDate'],
  data() {
    return {
      conversionRateChart: {
        options: {
          chart: {
            type: 'line',
            width: 160,
            height: 28,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: [{
                offset: 0,
                color: '#ff5b57',
                opacity: 1
              },
                {
                  offset: 50,
                  color: '#f59c1a',
                  opacity: 1
                },
                {
                  offset: 100,
                  color: '#90ca4b',
                  opacity: 1
                }]
            },
          },
          labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
          xaxis: {
            crosshairs: {
              width: 1
            },
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return '';
                }
              },
              formatter: (value) => {
                return value + '%'
              },
            },
            marker: {
              show: false
            }
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: 120
              }
            }
          }, {
            breakpoint: 1300,
            options: {
              chart: {
                width: 100
              }
            }
          }, {
            breakpoint: 1200,
            options: {
              chart: {
                width: 160
              }
            }
          }, {
            breakpoint: 900,
            options: {
              chart: {
                width: 120
              }
            }
          }, {
            breakpoint: 576,
            options: {
              chart: {
                width: 180
              }
            }
          }, {
            breakpoint: 400,
            options: {
              chart: {
                width: 120
              }
            }
          }]
        },
        series: [{
          data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.80]
        }]
      },
    };
  },
  mounted() {
    if (!this.companies.length) {
      this.fetchSummary()
    }
  },
  watch: {
    period() {
      if (this.startDate && this.endDate) {
        this.fetchSummary();
      }
    },

  },
  computed: {
    ...mapGetters({
      companies: 'getCommissionTabTwo'
    }),
    period() {
      return [this.startDate, this.endDate].join();
    },
  },
  methods: {
    fetchSummary() {
      let start_date = moment(this.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('fetchCommissionTabTwo', {start: start_date, end: end_date});
    }
  }
}
</script>
