<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <div class="card bg-blue">
          <div class="card-body">
            <h1 class="page-header mb-3 text-white">Dashboard</h1>
            <div class="d-sm-flex align-items-center mb-3">
              <date-range-picker ref="picker" v-model="dateRange.range"
                                 :autoApply="dateRange.autoApply"
                                 :linkedCalendars="dateRange.linkedCalendars"
                                 :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                                 :opens="dateRange.opens"
                                 :showDropdowns="dateRange.showDropdowns"
                                 :showWeekNumbers="dateRange.showWeekNumbers"
                                 :singleDatePicker="dateRange.singleDatePicker"
                                 :timePicker="dateRange.timePicker"
                                 :timePicker24Hour="dateRange.timePicker24Hour"
                                 class="btn btn-inverse mr-2"
                                 @update="updateValues">
                <template v-slot:input="picker">
                  <i class="fa fa-calendar fa-fw ml-n1"></i>
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  <b class="caret"></b>
                </template>
              </date-range-picker>
              <div v-if="dateRange.range.compareStartDate && dateRange.range.compareEndDate" class="text-cocoa f-w-600 mt-2 mt-sm-0">Comparison between date
                <span>{{ dateRange.range.compareStartDate }} and  {{ dateRange.range.compareEndDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- begin row -->
    <div class="row pb-5" id="row_wrapper">
      <div class="col-xl-6" v-if="$can.view($store.getters.getAuthUser, 'list-bidbonds-owned') || $can.view($store.getters.getAuthUser, 'list-bidbonds')">
        <div class="card pb-4 border-0 bg-dark text-white overflow-hidden">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-7 col-lg-8">
                <tab-one :end-date="dateRange.range.endDate" :start-date="dateRange.range.startDate"></tab-one>
              </div>
              <div class="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center">
                <img class="d-none d-lg-block" height="150px" src="@/assets/img/svg/img-1.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 child_row" v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')">
        <div class="card mb-2 border-0 bg-dark h-100 text-white text-truncate">
            <tab-two :end-date="dateRange.range.endDate" :start-date="dateRange.range.startDate" />
        </div>
      </div>
      <div class="col-xl-3 child_row" >
        <div class="card mb-2 border-0 h-100 bg-dark text-white text-truncate">
            <tab-three />
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row" style="margin-top:-30px;">
      <div v-if="listBidbonds" class="col-xl-8 col-lg-6 mb-2">
        <tab-four />
      </div>

      <div v-if="$can.view($store.getters.getAuthUser, 'list-reports')" class="col-xl-4 col-lg-6  mb-2">
        <!-- begin card -->
        <div class="card h-100 bg-dark border-0 text-white mb-3">
          <!-- rm performance -->
          <tab-five :end-date="dateRange.range.endDate" :start-date="dateRange.range.startDate"></tab-five>
          <!-- end rm performance -->

        </div>
        <!-- end card -->
      </div>

      <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')" class="col-xl-4 col-lg-6 mb-2">
        <div class="card h-100 border-0 bg-dark-darker text-white mb-3">
          <div id="b_perform" class="card-body">
            <div class="mb-3 text-grey">
              <b>BRANCH PERFORMANCE RANKING</b>
              <span class="text-grey ml-2"><i class="fa fa-info-circle" title="Branch Performance"></i></span>
            </div>
            <h4 class="m-b-10"><span data-animation="text" data-value="Top 5 Branches">Top 5 Branches</span></h4>
            <div class="text-grey m-b-1"><i class="fa fa-caret-up"></i>
              <span data-animation="text" data-value="By revenue generated"></span>
              By revenue generated
            </div>
          </div>
          <tab-six :end-date="dateRange.range.endDate" :start-date="dateRange.range.startDate"></tab-six>
        </div>
      </div>

      <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports') && listBidbonds" class="col-xl-4 col-lg-6 mb-2">
        <div class="card h-100 border-0 bg-dark text-white mb-3">
          <div class="card-body">
            <div class="mb-3 text-grey">
              <b>BID BOND PAYMENT</b>
              <span class="ml-2"><i class="fa fa-info-circle" title="Bid bond payments"></i></span>
            </div>
            <!-- show payments -->
            <tab-seven :end-date="dateRange.range.endDate" :start-date="dateRange.range.startDate"></tab-seven>
            <!-- end payments -->
          </div>
        </div>
      </div>

      <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')" class="col-xl-4 col-lg-6 mb-2">
        <div class="card h-100 border-0 bg-dark text-white mb-3">
          <div class="card-body">
            <div class="mb-3 text-grey">
              <b>BID BOND GENERATION BY: </b>
              <span class="ml-2"><i class="fa fa-info-circle" title="Bid bond generation"></i></span>
            </div>
            <!-- tab 8 data -->
            <tab-eight :end-date="dateRange.range.endDate" :start-date="dateRange.range.startDate"></tab-eight>
            <!-- end tab 8 data -->
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->

  </div>
</template>

<script>
import moment from 'moment/src/moment';
import tabOne from "@/views/dashboard/chart-tabs/tabOne";
import tabTwo from "@/views/dashboard/chart-tabs/tabTwo";
import tabThree from "@/views/dashboard/chart-tabs/tabThree";
import tabFour from "@/views/dashboard/chart-tabs/tabFour";
import tabFive from "@/views/dashboard/chart-tabs/tabFive";
import tabSix from "@/views/dashboard/chart-tabs/tabSix";
import tabSeven from "@/views/dashboard/chart-tabs/tabSeven";
import tabEight from "@/views/dashboard/chart-tabs/tabEight";

export default {
  components: {
    tabOne,
    tabTwo,
    tabThree,
    tabFour,
    tabFive,
    tabSix,
    tabSeven,
    tabEight
  },
  data() {
    return {
      dateRange: {
        opens: 'right',
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: true,
        showDropdowns: false,
        autoApply: true,
        linkedCalendars: false,
        range: {
          startDate: moment().subtract(7, 'days'),
          endDate: moment(),
          prevStartDate: moment().subtract(15, 'days').format('D MMMM YYYY'),
          prevEndDate: moment().subtract(8, 'days').format('D MMMM YYYY'),
          compareStartDate : '',
          compareEndDate:'',
        }
      }
    }
  },
  mounted() {
    this.adjustUI();
  },
  computed: {
    listBidbonds() {
      return this.$can.view(this.$store.getters.getAuthUser, 'list-bidbonds') || this.$can.view(this.$store.getters.getAuthUser, 'list-bidbonds-owned')
    }
  },
  methods: {
    updateValues(d) {
      const startDate = moment(d.startDate);
      const endDate = moment(d.endDate);
      const gap = endDate.diff(startDate, 'days');
      this.dateRange.range.prevStartDate = moment(startDate).subtract(gap, 'days').format('D MMMM YYYY');
      this.dateRange.range.prevEndDate = moment(startDate).subtract(1, 'days').format('D MMMM YYYY');
      this.dateRange.range.compareStartDate = startDate.format('D MMMM YYYY');
      this.dateRange.range.compareEndDate = endDate.format('D MMMM YYYY');
    },
    adjustUI(){
      if(document.getElementById('row_wrapper')){
        if(document.getElementById('row_wrapper').childElementCount < 3){
          for(let i = 0; i < document.getElementById('row_wrapper').childElementCount; i++) {
            if((document.getElementsByClassName('child_row')[i].classList).contains('col-xl-3')){
              document.getElementsByClassName('child_row')[i].classList.remove('col-xl-3');
              document.getElementsByClassName('child_row')[i].classList.add('col-xl-6');
            }
          }
        }
      }
    }
  }
}
</script>
<style>
#b_perform {
  background: no-repeat center right;
  background-image: url("~@/assets/img/branch.png");
  background-size: auto 40%;
}
</style>
