<template>
  <div class="card-body">
    <div class="mb-1">
      <b class="mb-1">BID BOND OPPORTUNITY</b>
      <span class="ml-2"><i class="fa fa-info-circle" title="Bid bond opportunity"></i></span>
    </div>
    <div class="d-flex align-items-center mb-1">
      <h2 class="text-white mb-0">{{ total_opportunity }}</h2>
      <div class="ml-auto">
        <apexchart :options="storeSessionChart.options" :series="storeSessionChart.series" height="28px"></apexchart>
      </div>
    </div>
    <div class="mb-2">
      <i class="fa fa-caret-up"></i>Opportunity per county
      <span class="float-right">Opportunity</span>
    </div>
    <div v-for="(tender,index) in tenders" :key="index" class="d-flex mb-1">
      <div class="d-flex align-items-center">
        <i class="fa fa-circle text-teal f-s-8 mr-2"></i>
        {{ tender.county }}
      </div>
      <div class="d-flex align-items-center ml-auto">
        <!--            <div class=" f-s-11"><i class="fa fa-caret-up"></i> Opportunity</div>-->
        <div class="width-50 text-right pl-2 f-w-600"><i class="fa fa-caret-up"></i> {{ tender.opportunity }}</div>
      </div>
    </div>
    <div class="mt-5 row ml-auto">
      <p style="font-size: 0.59rem;">For latest government tenders click <a href=" https://tenders.go.ke/incorporated"  target="_blank" rel="noopener noreferrer"> here</a></p>
      </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "tabThree",
  data() {
    return {
      storeSessionChart: {
        options: {
          chart: {
            type: 'line',
            width: 160,
            height: 28,
            sparkline: {
              enabled: true
            },
            stacked: false
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: [{
                offset: 0,
                color: '#00acac',
                opacity: 1
              },
                {
                  offset: 50,
                  color: '#348fe2',
                  opacity: 1
                },
                {
                  offset: 100,
                  color: '#5AC8FA',
                  opacity: 1
                }]
            },
          },
          labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
          xaxis: {
            crosshairs: {
              width: 1
            },
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return '';
                }
              },
              formatter: (value) => {
                return this.convertNumberWithCommas(value)
              },
            },
            marker: {
              show: false
            }
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: 120
              }
            }
          }, {
            breakpoint: 1300,
            options: {
              chart: {
                width: 100
              }
            }
          }, {
            breakpoint: 1200,
            options: {
              chart: {
                width: 160
              }
            }
          }, {
            breakpoint: 900,
            options: {
              chart: {
                width: 120
              }
            }
          }, {
            breakpoint: 576,
            options: {
              chart: {
                width: 180
              }
            }
          }, {
            breakpoint: 400,
            options: {
              chart: {
                width: 120
              }
            }
          }]
        },
        series: [{
          data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
        }]
      }
    };
  },
  mounted() {
    if (!this.tenders.length) this.fetchTenders();
  },
  watch: {
    summary() {
      this.total_opportunity = this.summary.total_opportunity;
    }
  },
  computed: {
    ...mapGetters({
      tenders: 'getTabThreeTenders',
      total_opportunity: 'getTabThreeOpportunity'
    })
  },
  methods: {
    fetchTenders() {
      this.$store.dispatch('fetchTendersTabThree');
    },
    convertNumberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}
</script>
