<template>
  <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')">
    <div class="card-body">
      <div class="mb-2">
        <b>RM PERFORMANCE</b>
        <span class="ml-2"><i class="fa fa-info-circle" title="RM Performance"></i></span>
      </div>
      <div>
        <span class="float-right">Commission Earned</span>
        <div class="clearfix">&nbsp;</div>
        <div v-for="(row,index) in rms" :key="index" class="d-flex align-items-center text-white mb-2">
          <div class="d-flex w-100">
            <div class="d-flex w-75">
              <avatar :size=32 :username="row.creator"></avatar>
              <span class="mt-1 text-capitalize" style="margin-left: 10px;">{{ row.creator }}</span></div>
            <div class="">KES {{ $number.format(parseInt(row.revenue)) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "tabFive",
  props: ['startDate', 'endDate'],
  mounted() {
    if (!this.rms.length) this.fetchRms();
  },
  watch: {
    period() {
      if (this.startDate && this.endDate) {
        this.fetchRms();
      }
    }
  },
  computed: {
    ...mapGetters({
      rms: 'getCommissionTabFive'
    }),
    period() {
      return [this.startDate, this.endDate].join();
    },
  },
  methods: {
    fetchRms() {
      let start_date = moment(this.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('fetchCommissionTabFive', {start: start_date, end: end_date});
    }
  }
}
</script>

<style scoped>

</style>
