<template>
  <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')">
    <div v-for="(payment,index) in payments" :key="index" class="d-flex align-items-center m-b-15">
      <div class="text-truncate">
        <div><i class="fa fa-circle text-red f-s-8 mr-1 mt-0"></i> {{ payment.company | truncate(23, '..') }}</div>
        <div class="">Bid bond value: {{ payment.currency }} {{ $number.format(payment.bid_value) }}</div>
      </div>
      <div class="ml-auto text-center">
        <div class="f-s-13">KES {{ $number.format(payment.amount) }}</div>
        <div class="text-grey f-s-10">Commission Collected</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "tabSeven",
  props: ['startDate', 'endDate'],
  mounted() {
    if (!this.payments.length) this.fetchPayments();
  },
  watch: {
    period() {
      if (this.startDate && this.endDate) {
        this.fetchPayments();
      }
    },
  },
  computed: {
    ...mapGetters({
      payments: 'getBidPaymentsTabSeven'
    }),
    period() {
      return [this.startDate, this.endDate].join();
    },
  },
  methods: {
    fetchPayments() {
      let start_date = moment(this.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('fetchBidPaymentsTabSeven', {start: start_date, end: end_date});
    }
  }
}
</script>
