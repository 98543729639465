<template>
  <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')">
    <div class="widget-list widget-list-rounded inverse-mode">
      <a v-for="(branch,index) in branches" :key="index" class="widget-list-item rounded-0 p-t-3" href="#">
        <div class="widget-list-media icon">
          <avatar :size=32 :username="branch.branch"></avatar>
        </div>
        <div class="widget-list-content">
          <div class="widget-list-title">{{ branch.branch }}</div>
        </div>
        <div class="widget-list-action text-nowrap">
          KES {{ $number.format(parseInt(branch.revenue)) }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "tabSix",
  props: ['startDate', 'endDate'],
  mounted() {
    if (!this.branches.length) {
      this.fetchBranchRevenue()
    }
  },
  watch: {
    period() {
      if (this.startDate && this.endDate) {
        this.fetchBranchRevenue();
      }
    }
  },
  computed: {
    ...mapGetters({
      branches: 'getBranchRevenueTabSix'
    }),
    period() {
      return [this.startDate, this.endDate].join();
    },
  },
  methods: {
    fetchBranchRevenue() {
      let start_date = moment(this.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('fetchBranchRevenueTabSix', {start: start_date, end: end_date});
    }
  }
}
</script>
