<template>
  <div v-if="$can.view($store.getters.getAuthUser, 'bidbonds-reports')">
    <div class="row align-items-center p-b-1">
      <div class="col-4">
        <div class="height-100 d-flex align-items-center justify-content-center">
          <img alt="manager" class=" mw-100 mh-100" src="@/assets/img/manager.png" />
        </div>
      </div>
      <div class="col-8">
        <div class="m-b-2 text-truncate">By Relationship Manager</div>
        <div class="m-b-2 f-s-11">{{ startDate | date }} - {{ endDate | date }}</div>
        <div class="d-flex align-items-center m-b-2">
          <div class="flex-grow-1">
            <div class="progress progress-xs rounded-corner bg-white-transparent-1">
              <div :style="`width: ${rm_percentage}%`" class="progress-bar progress-bar-striped bg-indigo"></div>
            </div>
          </div>
          <div class="ml-2 f-s-11 width-30 text-center">{{ rm_percentage }}%</div>
        </div>
      </div>
    </div>
    <hr class="bg-white-transparent-2 m-t-20 m-b-20" />
    <div class="row align-items-center">
      <div class="col-4">
        <div class="height-100 d-flex align-items-center justify-content-center">
          <img alt="customer" class="mw-100 mh-100" src="@/assets/img/customer.png" />
        </div>
      </div>
      <div class="col-8">
        <div class="m-b-2 text-truncate">By Customer</div>
        <div class=" m-b-2 f-s-11">{{ startDate | date }} - {{ endDate | date }}</div>
        <div class="d-flex align-items-center m-b-2">
          <div class="flex-grow-1">
            <div class="progress progress-xs rounded-corner bg-white-transparent-1">
              <div :style="`width: ${customer_percentage}%`" class="progress-bar progress-bar-striped bg-warning"></div>
            </div>
          </div>
          <div class="ml-2 f-s-11 width-30 text-center">{{ customer_percentage }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "tabEight",
  props: ['startDate', 'endDate'],
  data() {
    return {
      rm_percentage: null,
      customer_percentage: 0,
      total_bids: 0,
    };
  },
  mounted() {
    if(!this.rm_percentage) this.fetchPercentages();
  },
  watch: {
    summary() {
      this.rm_percentage = this.summary.rms;
      this.customer_percentage = this.summary.customers;
      this.total_bids = this.summary.total_bids;
    },
    period() {
      if (this.startDate && this.endDate) {
        this.fetchPercentages();
      }
    }
  },
  computed: {
    ...mapGetters({
      summary: 'getBidComparisonTabEight'
    }),
    period() {
      return [this.startDate, this.endDate].join();
    },
  },
  methods: {
    fetchPercentages() {
      let start_date = moment(this.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('fetchBidComparisonTabEight', {start: start_date, end: end_date});
    }
  }
}
</script>
